import { gql } from '@apollo/client';
import { requestGql } from 'modules/common/utils/request-gql';
import { DocumentsDataInterface } from 'modules/documents/interfaces/documents-data.interface';
import { DocumentsFetchArgsInterface } from 'modules/documents/interfaces/documents-fetch-args.interface';

export interface useFetchDocumentsHookInterface {
  fetchDocuments: (query?: DocumentsFetchArgsInterface) => Promise<useFetchDocumentsResultInterface>;
}

export interface useFetchDocumentsResultInterface {
  data: DocumentsDataInterface[],
  totalCount: number
}

export const useFetchDocuments = (): useFetchDocumentsHookInterface => {

  const fetchDocuments = async (args: DocumentsFetchArgsInterface): Promise<useFetchDocumentsResultInterface> => requestGql(
    {
      query: gql`
          query FetchDocuments(
            $filter: DocumentFilterArgType!
            $limit: Float
            $offset: Float
            $order: DocumentOrderArgType
            ) {
            documents(
              filter: $filter, 
              limit: $limit, 
              offset: $offset, 
              order: $order
            ) {
              data{
                product
                id
                customerPoNumber
                dueDate
                helmContractId
                helmOrderId
                helmDeliveryId
                netValue
                currency
                product
                shipTo
                documentType
                status
                archiveDocId
                archiveId
                fileName
                documentTypeId
                documentId
              }
            totalCount
            }
          }
        `,
      variables: args
    },
    null,
    'documents',
  )

  return {
    fetchDocuments,
  };
};