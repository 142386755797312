import { roqMakeStyles } from 'modules/common/utils';

export const useDeliveryTableSectionStyles = roqMakeStyles((theme) => ({
  'paper--root': {
    boxShadow: '0 0.25rem 1rem rgb(13 28 70 / 5%)',
    borderRadius: '2px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  cardHeaderHeading: {
    fontSize: '20px',
    fontWeight: '800',
    color: 'black',
  },
  cardItemHeading: {
    fontSize: '13px',
    fontWeight: '400',
  },
  cardItemSubHeading: {
    fontSize: '15px',
    fontWeight: '800',
    color: 'black',
  },
  cardItemAddressValue: {
    width: '70%',
    fontSize: '15px',
    fontWeight: '800',
    color: 'black',
  },
  filterBox: {
    padding: '16px', display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      justifyContent: 'space-between',
    }
  },
  filterBoxButtonContainer: {
    justifyContent: 'flex-end',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap'
    }
  },
  filterBoxButton: {
    minWidth: 'auto', whiteSpace: 'nowrap', height: '20px !important', minHeight: '17px',
    boxSizing: 'content-box', padding: '6px 8px', fontSize: '14px', gap: '8px', alignItems: 'normal', fontWeight: 800
  },
  filterBoxChips: { display: 'flex', flexWrap: 'wrap', columnGap: '12px' },
  customChip: {
    alignItems: 'center',
    '& .MuiChip-filled': {
      borderRadius: '2px',
      backgroundColor: '#CCDEE3',
      fontSize: '12px',
      color: '#1F4556',
      padding: '4px',
      lineHeight: '100%',
      '& svg': {
        color: '#006173',
      },
      [theme.breakpoints.down('md')]: {
        height: 'auto',
      },
      '& .MuiChip-label': {
        [theme.breakpoints.down('md')]: {
          textOverflow: 'revert-layer',
          whiteSpace: 'pre-wrap !important',
        },
      }
    },
  },
}));
