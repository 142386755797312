import { DocumentsStatusEnum } from 'modules/common/enums/documents-status.enum';
import { ContractTypeEnum } from 'modules/contract-details/enums';
import { DocumentTypeOptionInterface } from 'modules/documents/interfaces/document-type-option.interface';
import { FilterByStateInterface } from 'modules/filter-by';
import { tableStateInterface } from 'modules/filter-by/filter-by.slice';
import { filterBySelector } from 'modules/filter-by/selectors';
import { AddressOptionInterface } from 'modules/forms/components/address-search-multi/address-search-multi.component';
import { AutocompleteCompanyOptionItemInterface } from 'modules/forms/components/autocomplete-company/autocomplete-company.component';
import { LastUpdatedByUserOptionInterface } from 'modules/forms/components/config-last-updated-by-user-search/last-updated-by-user-search.component';
import { IncotermsInterface } from 'modules/forms/components/incoterms-autocomplete-search/interfaces/incoterms.interface';
import { ProductAutocompleteOptionInterface } from 'modules/forms/components/product-autocomplete-search/interfaces';
import { OptionInterface } from 'modules/forms/components/select/select.component';
import { useSelector } from 'react-redux';
import { OrderTypeEnum } from 'views/orders/enums';

export interface UseFilterByHookInterface {
  isLoading: boolean;
  contractState: {
    years: OptionInterface[];
    products: ProductAutocompleteOptionInterface[];
    statuses: OptionInterface[];
    shipToAddresses: AddressOptionInterface[];
    tableState: tableStateInterface;
  };
  companyConfigState: {
    products: ProductAutocompleteOptionInterface[];
    users: LastUpdatedByUserOptionInterface[];
    company: AutocompleteCompanyOptionItemInterface;
    statuses: OptionInterface[];
    tableState: tableStateInterface;
  };
  orderState: {
    products: ProductAutocompleteOptionInterface[];
    orderTypes: OptionInterface[];
    statuses: OptionInterface[];
    shipToAddresses: AddressOptionInterface[];
    tableState: tableStateInterface;
    searchId: string;
  };
  deliveryState: {
    products: ProductAutocompleteOptionInterface[];
    shipToAddresses: AddressOptionInterface[];
    transportation: OptionInterface[];
    statuses: OptionInterface[];
    incoterms: IncotermsInterface[];
    tableState: tableStateInterface;
    orderType?: OptionInterface<OrderTypeEnum>[];
    searchById?: string;
  };
  orderCustomerState: {
    orderId: string;
    customerOrderReference: string;
    fromOrderDate: Date;
    productId: string;
    productName: string;
    toOrderDate: Date;
    fromDeliveryDate: Date;
    toDeliveryDate: Date;
    contractSearchById: string;
    contractSearchByType: ContractTypeEnum;
  };
  companyTableState: {
    companies: AutocompleteCompanyOptionItemInterface[];
  };
  invoicesState: {
    products: ProductAutocompleteOptionInterface[];
    shipToAddresses: AddressOptionInterface[];
    status: OptionInterface<DocumentsStatusEnum>[];
    tableState: tableStateInterface;
    deliveryId: string;
    orderId: string;
    incoterms: IncotermsInterface[];
    orderType: OptionInterface<OrderTypeEnum>[];
    searchText: string;
    documentTypes: DocumentTypeOptionInterface[];
  };
  otherDocumentsState: {
    product: ProductAutocompleteOptionInterface;
    documentTypes: DocumentTypeOptionInterface[];
    tableState: tableStateInterface;
  };
}

export const useFilterBy = (): UseFilterByHookInterface => {
  const {
    isLoading,
    contractState,
    orderCustomerState,
    orderState,
    companyTableState,
    deliveryState,
    companyConfigState,
    invoicesState,
    otherDocumentsState,
  }: FilterByStateInterface = useSelector(filterBySelector);

  return {
    isLoading,
    contractState,
    orderCustomerState,
    orderState,
    companyTableState,
    deliveryState,
    companyConfigState,
    invoicesState,
    otherDocumentsState,
  };
};
