/* eslint-disable @roq/filename-suffix-mismatch */
import { roqMakeStyles } from 'modules/common/utils';

export const useEllipseTextStyles = roqMakeStyles((theme) => ({
  oneLineEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '150px',
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '160%',
    letterSpacing: '0.17px',
  },
  textBox: {
    display: 'flex',
  },
  tooltip: {
    fontSize: '12px',
    lineHeight: '14px',
    '& .MuiTooltip-tooltip': {
      backgroundColor: theme.palette.primary.main, // Set the maximum height of the tooltip content
    },
    '& .MuiTooltip-arrow': {
      color: theme.palette.primary.main, // Set the desired arrow color
    },
  },
  countChip: {
    borderRadius: 2,
    marginTop: '-4px',
    marginLeft: '6px',
  },
}));
