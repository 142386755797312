import { Button, Typography } from '@mui/material';
import { XCircle } from '@phosphor-icons/react';
import React from 'react';

export const ResetButton = ({ onClickHandler }: { onClickHandler: () => void }): React.ReactElement => (
  <Button
    variant="filterButton"
    color="secondary"
    onClick={onClickHandler}
    sx={{
      display: 'flex',  // Use flexbox for layout
      justifyContent: 'center',  // Center content horizontally
      alignItems: 'center',  // Center content vertically
      lineHeight: '0',
      fontSize: 'unset',
      alignSelf: 'center',
      minHeight: '29px !important'
    }}
  >
    <XCircle size={'20'} style={{ height: '20px', width: '20px' }} />
    <Typography
      sx={{
        color: '#68C0B5',
        fontSize: '14px',
        fontWeight: 600,
        fontFamily: 'Graphik-SemiBold',
        lineHeight: '16.80px',
        letterSpacing: '0.40px',
        wordWrap: 'break-word',
        marginTop: '3px'
      }}
    >
      Reset all filters &nbsp;
    </Typography>
  </Button>
)