import { SxProps, Typography } from '@mui/material';
import Link from 'next/link';
import React from 'react';

interface DetailLinkStyleInterface {
  children?: React.ReactElement;
  href: string;
  value: string;
  stylingProps?: SxProps;
}

export const DetailStyledLink = (props: DetailLinkStyleInterface): React.ReactElement => {
  const { href, children, value, stylingProps = {} } = props;
  return (
    <Link href={href} aria-disabled={!value}>
      {!children ?
        <Typography fontSize={'inherit'}
          fontFamily={value ? 'Graphik-SemiBold' : 'Graphik-Regular'}
          color={value ? "primary" : "black"}
          fontWeight={value ? 600 : 400}
          letterSpacing={'0.17px'}
          lineHeight={'24px'}
          sx={{
            ...(value ? { cursor: 'pointer' } : { pointerEvents: 'none' }),
            underline: 'none',
            ...stylingProps
          }}
        >
          {value ? value : 'N/A'}
        </Typography>
        :
        children
      }
    </Link>
  )
};