import { Chip, CircularProgress } from '@mui/material';
import { CustomTooltip } from 'modules/common/components/custom-tooltip/custom-tooltip.component';
import { useEllipseTextStyles } from 'modules/common/components/ellipse-text/ellipse-text.styles';
import { useAsyncOperation } from 'modules/common/hooks';
import React, { FunctionComponent, ReactElement, useEffect, useRef, useState } from 'react';

export interface EllipseTextComponentInterface {
  showNumber?: number;
  text: string | ReactElement;
  isTooltip?: boolean;
  color?: string;
  textFieldWidth?: string;
  handleFetchValues?: (args: string[]) => Promise<string[]>;
  args?: string[];
  textStyleProps?: React.CSSProperties;
  textWrapperProps?: React.CSSProperties;
}

export const EllipseTextComponent: FunctionComponent<EllipseTextComponentInterface> = (props) => {
  const { showNumber = false, text, isTooltip = true, color, textFieldWidth, handleFetchValues, args, textStyleProps, textWrapperProps= {} } = props;
  const classes = useEllipseTextStyles();
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textElementRef = useRef(null);
  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [ellipseText, setEllipseText] = useState(text);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const { status, initiateOperation, isLoading } = useAsyncOperation({
    callback: handleFetchValues,
  });

  // Event handlers for tooltip hover
  const handleTooltipEnter = () => {
    if (handleFetchValues && args?.length > 1) {
      setIsFetchingMore(true);
      hoverTimeoutRef.current = setTimeout(() => {
        void initiateOperation(args);
      }, 1000);
    }
  };

  const handleTooltipLeave = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current); // Cancel the timeout if mouse leaves before 2 seconds
    }
  };

  useEffect(() => {
    if (status?.result) {
      setIsFetchingMore(false);
      setEllipseText(status.result.join(' , '));
    } else if (isLoading) {
      setIsFetchingMore(true);
    } else if (status?.error) {
      setIsFetchingMore(false);
    }
  }, [status]);


  useEffect(() => () => {
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
      }
  }, []);

  useEffect(() => {
    const element = textElementRef.current;
    if (element) {
      setIsOverflowing(element.scrollWidth > element.clientWidth);
    }
  }, [text, textFieldWidth, textStyleProps]);

  return (
    <>
      <div className={classes.textBox}>
        <div className={classes.tooltip} style={textWrapperProps}>
          {isOverflowing ? (
            <CustomTooltip
              title={
                isTooltip ? (
                  <>
                    <span>{status?.result ? ellipseText : text} </span>
                    {isFetchingMore && <CircularProgress color="secondary" size={10} />}
                  </>
                ) : (
                  ''
                )
              }
              PopperProps={{
                disablePortal: true,
              }}

              onMouseEnter={handleTooltipEnter}
              onMouseLeave={handleTooltipLeave}
            >
              <div
                className={classes.oneLineEllipsis}
                style={{ color, ...(textFieldWidth && { width: textFieldWidth }), ...textStyleProps }}
                ref={textElementRef}
              >
                {status?.result ? ellipseText : text}
              </div>
            </CustomTooltip>
          ) : (
            <div
              className={classes.oneLineEllipsis}
                style={{ color, ...(textFieldWidth && { width: textFieldWidth }), ...textStyleProps }}
              ref={textElementRef}
            >
              {text}
            </div>
          )}
        </div>
        {showNumber as number > 0 && (
          <Chip size="small" component="span" className={classes.countChip} label={`+${showNumber}`} />
        )}
      </div>
    </>
  );
};
