import {  TooltipProps } from '@mui/material';
import { CustomTooltip } from 'modules/common/components/custom-tooltip/custom-tooltip.component';
import { useToolTipStyles } from 'modules/common/components/tool-tip-common/tool-tip-common.styles';
import React, { FunctionComponent, ReactElement } from 'react';

interface ToolTipInterface extends TooltipProps {
  title: string;
  children: ReactElement
}

export const ToolTipCommon: FunctionComponent<ToolTipInterface> = (props) => {
  const { title, children, ...rest } = props;
  const classes = useToolTipStyles();

  return (
    <>
      <div className={classes.tooltip}>
        <CustomTooltip
          title={title}
          arrow
          PopperProps={{
            disablePortal: true,
          }}
          {...rest}
        >
          {children}
        </CustomTooltip>
      </div>
    </>
  );
};
