import { publicConfig } from 'configuration/app';
import { saveAs } from 'file-saver';
import { authSelector } from 'modules/auth/selectors';
import { DocumentsActionInterface } from 'modules/common/interfaces/documents-action.interface';
import { DocumentsActionReturnInterface } from 'modules/common/interfaces/documents-actions-return.interface';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';


export const useDocumentsActions = (): DocumentsActionReturnInterface => {
  const { accessToken } = useSelector(authSelector);
  const constructURL = useCallback((doc: DocumentsActionInterface) => {
    const { archiveId, documentType, archiveDocId } = doc;

    const params = new URLSearchParams({
      archiveId,
      documentType,
      archiveDocId,
    });

    const url = `${publicConfig.backend.url}/attachments/getDocumentAttachment?${params}`;
    return url;
  }, []);

  const downloadFile = async (doc: DocumentsActionInterface, isPreview = false): Promise<void> => {
    const urls = constructURL(doc);
    await fetch(urls, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
      credentials: 'include'
    })
      .then((resp) => resp.blob())
      .then((blob) => {
        if (!isPreview) {
          saveAs(blob, `${doc.fileName}`);
        } else {
          const fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        }
      })
  };

  return {
    downloadFile
  }
} 