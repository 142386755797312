import { StoreInterface } from 'configuration/redux/store';
import { FilterByStateInterface } from 'modules/filter-by/filter-by.slice';
import { createSelector } from 'reselect';

export const filterBySelector = createSelector<
  [(state: StoreInterface) => FilterByStateInterface],
  FilterByStateInterface
>(
  (state) => state.filterBy,
  (values) => values,
);
