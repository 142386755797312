/* eslint-disable @roq/filename-suffix-mismatch */
import { roqMakeStyles } from 'modules/common/utils';

export const useToolTipStyles = roqMakeStyles((theme) => ({
  tooltip: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: theme.palette.primary,
    },
    '& .MuiTooltip-arrow': {
      color: theme.palette.primary.main,
    },
  },
  oneLineEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '150px',
  },
}));
