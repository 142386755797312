import { Button } from '@mui/material';
import { Funnel } from '@phosphor-icons/react';

export const FilterButtonComponent = ({ onClickHandler }: { onClickHandler: () => void }): React.ReactElement =>
  <Button
    variant="filterButton"
    color="primary"
    onClick={onClickHandler}
  >
    <Funnel size={20} />
    Filter &nbsp;
  </Button>